<template>
  <div class="dialog-contain">
    <el-dialog
      title=""
      class="contact-dialog"
      :show-close="false"
      :visible.sync="dialogContactVisible"
      append-to-body
    >
      <div class="contact-body-contain">
        <span class="title">{{ headTitle }}</span>
        <div @click="closeClick">
          <en-icon
            name="guanbi-danchuang"
            class="close-icon"
            size="14px"
            :style="{ color: '#A9B5C6' }"
          ></en-icon>
        </div>

        <div class="content-contain">
          <en-tabs
            v-model="activeTab"
            :list="tabList"
            type="white"
            @change="changeTabs"
          >
          </en-tabs>

          <!-- <div class="div-line"></div> -->

          <template v-if="activeTab === 'often'">
            <div class="often-contain">
              <el-input
                v-model="searchInput"
                size="mini"
                placeholder="搜索关键字"
                suffix-icon="el-icon-search"
                class="contact-search"
              />
              <div class="section-title">
                {{ middleTitle }}
              </div>

              <el-table
                :data="tableData"
                :show-header="false"
                style="width: 100%"
                height="345"
                class="contact-table"
                @row-click="rowClick"
                @cell-mouse-enter="cellMouseEnter"
                @cell-mouse-leave="cellMouseLeave"
                v-en-loading="loading"
              >
                <el-table-column prop="name" label="姓名" width="42">
                  <template slot-scope="scope">
                    <div class="contact-img">
                      <en-user-logo
                        :user-name="scope.row.name ? scope.row.name.substr(0, 1) : ''"
                        size="30"
                      ></en-user-logo>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column prop="name" width="376"></el-table-column>

                <el-table-column prop="idNumber" width="300">
                  <template v-slot="{ row }">
                    {{row.idNumber | hideCardNo(row.idType)}}
                  </template>
                </el-table-column>

                <el-table-column prop="flag">
                  <template slot-scope="scope">
                    <div
                      v-if="scope.row.flag === '000'"
                      class="edit-button"
                      @click="editClick(scope)"
                    >
                      <en-icon
                        name="bianji-liebiao"
                        class="edit-icon"
                        size="small"
                        :style="{ color: '#26C393' }"
                      ></en-icon>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>

          <template v-else>
            <div class="add-contain">
              <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="80px"
              >
                <el-form-item label="姓名" prop="name">
                  <el-input
                    v-model="form.name"
                    clearable
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>

                <el-form-item label="证件类型" prop="idType">
                  <el-select v-model="form.idType" placeholder="请选择证件类型">
                    <el-option
                      v-for="item in typeList"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    />
                  </el-select>
                </el-form-item>

                <el-form-item label="证件号码" prop="idNumber">
                  <el-input
                    v-model="form.idNumber"
                    clearable
                    placeholder="请输入证件号码"
                  ></el-input>
                </el-form-item>

                <el-form-item
                  v-if="form.idType !== '000'"
                  label="生日"
                  prop="birthDay"
                >
                  <div style="position:relative;">
                    <el-date-picker
                      v-model="form.birthDay"
                      type="date"
                      placeholder="选择日期"
                      suffix-icon="el-icon-date"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                    <en-icon
                      name="riqi-shanglvguanli"
                      size="16px"
                      class="custom-prefix"
                    ></en-icon>
                  </div>
                </el-form-item>

                <el-form-item label="手机号码" prop="mobile">
                  <el-input
                    v-model="form.mobile"
                    clearable
                    placeholder="请输入手机号码"
                  ></el-input>
                </el-form-item>
              </el-form>

              <en-button
                size="small"
                type="blue"
                class="confirm-button"
                @click="confirmClick"
              >
                确定
              </en-button>
            </div>
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash";
import {
  request, isIdCard, isHongKongCard, isTaiWanCard, hideCardNo
} from "en-js";
import { contactService } from "@/api/businessTravel";
import contact, {
  UpdateNameColor
} from "./contact";
import { getAgeFromIdNumber, getAgeFromDate } from "../../../../tools/util";

export default {
  name: "EnContact",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: true
    },
    editContact: {
      type: Object,
      default: null
    },
    existList: {
      type: Array,
      default: null
    }
  },
  mixins: [contact],
  filters: {
    hideCardNo
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      const reg = /^1\d{10}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
      return "";
    };
    const validatorIdNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请输入证件号码"));
      }
      const { idType } = this.form;
      let errMsg = "";
      switch (idType) {
        case "000":
          errMsg = isIdCard(value) ? "" : "请输入正确的身份证";
          if (!errMsg) {
            errMsg = getAgeFromIdNumber(value) < 12 ? "抱歉，暂不支持预定儿童票" : "";
          }
          break;
        case "001":
          errMsg = "";
          break;
        case "002":
          errMsg = isHongKongCard(value) ? "" : "请输入正确的港澳共通行证";
          break;
        case "003":
          errMsg = isTaiWanCard(value) ? "" : "请输入正确的台湾证";
          break;
      }
      if (errMsg) {
        return callback(new Error(errMsg));
      }
      return callback();
    };
    const validatorBirthDay = (rule, value, callback) => {
      const age = getAgeFromDate(value);
      if (age < 12) {
        return callback(new Error("抱歉，暂不支持预定儿童票"));
      }
      return callback();
    };
    return {
      dialogContactVisible: true,
      searchInput: "",

      dialogTipsVisible: false,
      dialogType: "message",
      dialogTips: "",
      dialogTitle: "温馨提示:",

      headTitle: "选择人员",
      middleTitle: "选择历史",
      tabList: [
        {
          id: "often",
          name: "常用"
        },
        {
          id: "add",
          name: "新增"
        }
      ],
      activeTab: "often",
      form: {
        id: "",
        name: "",
        idType: "",
        idNumber: "",
        mobile: "",
        birthDay: ""
      },
      // /000身份证 001护照 002港澳 003台胞
      typeList: [
        {
          id: "000",
          name: "身份证"
        },
        {
          id: "001",
          name: "护照"
        },
        {
          id: "002",
          name: "港澳通行证"
        },
        {
          id: "003",
          name: "台胞证"
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          },
          {
            max: 20,
            message: "最多输入20个字符",
            trigger: "blur"
          }
        ],
        idType: [
          {
            required: true,
            message: "请选择证件类型",
            trigger: "blur"
          }
        ],
        idNumber: [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "blur"
          },
          {
            validator: validatorIdNumber,
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            max: 11,
            message: "最多输入11位手机号",
            trigger: "blur"
          },
          {
            validator: checkPhone,
            trigger: "blur"
          }
        ],
        birthDay: [
          {
            required: true,
            message: "请输入选择生日",
            trigger: "change"
          },
          {
            validator: validatorBirthDay,
            trigger: "change"
          }
        ]
      },
      tableData: [],
      oftenResult: [],
      loading: null
    };
  },
  watch: {
    dialogContactVisible(val) {
      this.$emit("update:dialogVisible", val);
    },
    tableData() {
      this.tableData.forEach((obj) => {
        UpdateNameColor(obj);
      });
    },
    searchInput(val) {
      if (val.length === 0) {
        this.middleTitle = "选择历史";
      } else {
        this.middleTitle = "选择搜索";
      }
      this.search();
    }
  },
  mounted() {
    this.search = debounce(this.getDatas, 500);
    this.dialogContactVisible = this.dialogVisible;
    if (this.editContact) {
      this.activeTab = "add";
      this.form.name = this.editContact.name;
      this.form.idType = this.editContact.idType;
      this.form.idNumber = this.editContact.idNumber;
      this.form.mobile = this.editContact.mobile;
      this.form.birthDay = this.editContact.birthDay;
    }
    this.getDatas();
  },
  methods: {
    @request(true, "loading")
    async getDatas() {
      const { searchInput } = this;
      let res = [];
      if (searchInput.length > 0) {
        res = await contactService.tripQueryContacts({
          name: this.searchInput
        });
      } else {
        res = await contactService.tripHisContacts();
      }
      this.tableData = res || [];
    },
    changeTabs(res) {
      this.headTitle = "选择人员";
      this.form.id = "";
      this.form.name = "";
      this.form.idType = "";
      this.form.idNumber = "";
      this.form.mobile = "";
      this.form.birthDay = "";
      if (res.id === "often") {
        this.tabList[1].name = "新增";
      }
    },
    closeClick() {
      this.dialogContactVisible = false;
    },
    imageStyle(scope) {
      return `background-color:${scope.row.color};`;
    },
    rowClick(row) {
      this.confirmSelectContact(row);
    },
    cellMouseEnter(row) {
      if (row.flag === "000") {
        row.isEdit = true;
      } else {
        row.isEdit = false;
      }
    },
    cellMouseLeave(row) {
      row.isEdit = false;
    },
    editClick(scope) {
      scope.row.isEdit = false;
      this.tabList[1].name = "编辑";
      this.activeTab = "add";
      this.form.id = scope.row.id;
      this.form.name = scope.row.name;
      this.form.idType = scope.row.idType;
      this.form.idNumber = scope.row.idNumber;
      this.form.mobile = scope.row.mobile;
      this.form.birthDay = scope.row.birthDay;
    },
    confirmSelectContact(obj) {
      let isExist = false;
      for (let i = 0; i < this.existList?.length; i++) {
        const tmp = this.existList[i];
        if (tmp.id === obj.id) {
          isExist = true;
          break;
        }
      }
      if (isExist) {
        this.$message.error("不能重复添加");
      } else {
        this.$emit("getEnContactConfirm", obj);
        this.closeClick();
      }
    },
    @request()
    async savePerson() {
      const params = {
        id: this.form.id,
        name: this.form.name,
        idType: this.form.idType,
        idNumber: this.form.idNumber,
        mobile: this.form.mobile,
        flag: this.form.flag || "000",
        birthDay: this.form.birthDay
      };
      let res = null;
      if (params.id) {
        res = await contactService.tripUpdateContacts(params);
        res = res || params;
      } else {
        res = await contactService.tripAddContacts(params);
      }
      this.confirmSelectContact(res);
      // this.getDatas();
      // this.closeClick();
    },
    /**
     * 确定按钮
     */
    confirmClick() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.savePerson();
        } else {
          const { idType, idNumber, birthDay } = this.form;
          let errMsg = "";
          switch (idType) {
            case "000":
              errMsg = getAgeFromIdNumber(idNumber) < 12 ? "抱歉，暂不支持预定儿童票" : "";
              break;
            default:
              errMsg = birthDay && getAgeFromDate(birthDay) < 12 ? "抱歉，暂不支持预定儿童票" : "";
              break;
          }
          if (errMsg) {
            this.$message.warning(errMsg);
          }
        }
        return false;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-body-contain {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 900px;
  height: 540px;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  .title {
    position: absolute;
    left: 20px;
    top: 20px;
    height: 14px;
    font-size: 14px;
    line-height: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    text-align: left;
    color: rgba(51, 51, 51, 1);
  }

  .close-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 14px;
    height: 14px;
  }

  .content-contain {
    margin-top: 42px;
    height: calc(100% - 42px);
    text-align: left;
    // padding:0 20px;

    & /deep/ .el-table::before {
      height: 0;
    }
    & /deep/ .en-tabs {
      line-height: 40px;
    }
    & /deep/ .en-tabs-item {
      height: 40px;
      font-size: 12px;
      padding:0;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      text-align: center;
      width: 120px;
      span{
        display:block;
        width:100%;
      }
      &.active {
        color: #3e90fe;
      }
    }

    .div-line {
      width: 100%;
      height: 0.5px;
      background-color: rgba(232, 236, 242, 1);
    }

    // 常用
    .often-contain {
      height: calc(100% - 40px - 10px - 15px);
      margin: 10px 20px 15px;
      border: 1px solid #e8ecf2;
      border-radius: 4px;
      & /deep/ .el-input__inner {
        // width: calc(100% - 0px);
        // padding-left: 12px;
        // padding-right: 22px;
      }

      .contact-search {
        width: 836px;
        margin: 10px;
      }
      .section-title {
        width: 100%;
        height: 32px;
        background: rgba(246, 250, 253, 1);
        border-top: 1px solid #e8ecf2;
        border-bottom: 1px solid #e8ecf2;

        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(99, 108, 120, 1);
        line-height: 32px;
        padding-left: 12px;
      }

      .contact-img {
        background-color: orange;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        text-align: center;
        font-size: 12px;
        line-height: 30px;
        color: #fff;
      }

      .contact-name {
        display: block;
        width: 30px;
        height: 30px;
      }

      & /deep/ .edit-button {
        padding: 0;
      }

      .edit-button {
        padding-right: 13px;
        height: 39px;
        width: 100px;
        background-color: transparent;
        border-color: transparent;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .edit-icon {
          float: right;
        }
      }

      & /deep/ .contact-table {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      & /deep/ .el-table__row /deep/ td,
      & /deep/ .el-table__row /deep/ th {
        height: 40px;
        border-color: transparent;

        & /deep/ .cell {
          padding: 0 0 0 12px;
        }
      }
    }

    // 新增
    .add-contain {
      height: calc(100% - 20px - 16px);
      margin: 20px 20px 16px;

      & /deep/ .el-form-item {
        margin-bottom: 16px;
      }

      & /deep/ .el-form-item__content {
        margin-left: 72px !important;
      }

      & /deep/ .el-form-item__label {
        width: 72px !important;
        text-align: right;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
      }

      & /deep/ .el-form-item__label:before {
        display: none;
      }

      & /deep/ .el-input__inner {
        width:790px;
        padding-left: 12px;
      }

      & /deep/ .el-input__icon {
        line-height: 32px !important;
      }

      & /deep/ .el-date-editor {
        width: 786px;

        .el-icon-date {
          display: none;
        }

        .el-input__inner {
          padding-left: 34px;
        }

        .el-input__prefix {
          left: 6px !important;
        }
      }

      .custom-prefix {
        position: absolute;
        top: 7px;
        left: 11px;
        fill: #a9b5c6;
      }

      .confirm-button {
        position: absolute;
        bottom: 16px;
        right: 20px;
        width: 72px;
        height: 32px;
        background-color: #4895df;
        border-radius: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #fff;
        line-height: 12px;
      }
    }
  }
}

.contact-dialog {
  & /deep/ .el-dialog__header {
    padding: 0px;
    margin: 0px;
    height: 0px;
  }

  & /deep/ .el-dialog__body {
    padding: 0px;
    margin: 0px;
  }
}
</style>
