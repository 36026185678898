import { request } from "en-js";
import { tripService } from "@/api";

export const UpdateNameColor = (obj) => {
  let name = obj.name === "undefined" ? "" : obj.name;
  const cls = ["#78cd60", "#fda800", "#73ade8"];

  const color = cls[Math.floor(Math.random() * 3) % 3];
  const zhcnReg = /[\u4e00-\u9fa5]/g;
  const numReg = /^[0-9]*$/;
  // const enReg = /^[A-Za-z]+$/;
  if (zhcnReg.test(name) || numReg.test(name)) {
    if (name.length > 2) {
      name = name.substring(name.length - 2, name.length);
    }
  } else {
    name = name.split(" ");
    if (name.length === 1) {
      // if(enReg.test(name[0])){
      name = name[0].substring(0, 2);
      // }else{
    } else {
      name = name[name.length - 2].substring(0, 1)
        + name[name.length - 1].substring(0, 1);
    }
    name = name.toUpperCase();
  }
  obj.subName = name;
  obj.color = color;
};

export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      }
      const reg = /^1[3-9][0-9]\d{8}$/;
      console.log(reg.test(value));
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的手机号"));
      }
      return "";
    };
    return {
      dialogContactVisible: true,
      searchInput: "",

      dialogTipsVisible: false,
      dialogType: "message",
      dialogTips: "",
      dialogTitle: "温馨提示:",

      headTitle: "添加人员",
      middleTitle: "选择历史",
      tabList: [
        {
          id: "often",
          name: "常用"
        },
        {
          id: "add",
          name: "新增"
        }
      ],
      activeTab: "often",
      form: {
        id: "",
        name: "",
        idType: "",
        idNumber: "",
        mobile: "",
        birthDay: ""
      },
      // /000身份证 001护照 002港澳 003台胞
      typeList: [
        {
          id: "000",
          name: "身份证"
        },
        {
          id: "001",
          name: "护照"
        },
        {
          id: "002",
          name: "港澳通行证"
        },
        {
          id: "003",
          name: "台胞证"
        }
      ],
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur"
          },
          {
            max: 20,
            message: "最多输入20个字符",
            trigger: "blur"
          }
        ],
        idType: [
          {
            required: true,
            message: "请选择证件类型",
            trigger: "blur"
          }
        ],
        idNumber: [
          {
            required: true,
            message: "请输入证件号码",
            trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur"
          },
          {
            max: 11,
            message: "最多输入11位手机号",
            trigger: "blur"
          },
          {
            validator: checkPhone,
            trigger: "blur"
          }
        ],
        birthDay: [
          {
            required: true,
            message: "请输入选择生日",
            trigger: "blur"
          }
        ]
      },
      tableData: [],
      oftenResult: [],
      searchResult: []
    };
  },
  watch: {
    dialogContactVisible(val) {
      this.$emit("update:dialogVisible", val);
    },
    tableData() {
      this.tableData.forEach((obj) => {
        UpdateNameColor(obj);
      });
    },
    searchInput(val) {
      if (val.length === 0) {
        this.middleTitle = "选择历史";
        this.tableData = [...this.oftenResult];
      } else {
        this.middleTitle = "选择搜索";
        this.requestSearchContactList(val);
      }
      console.log(val);
    }
  },
  mounted() {
    this.dialogContactVisible = this.dialogVisible;
    if (this.editContact) {
      this.activeTab = "add";
      this.form.id = this.editContact.id;
      this.form.name = this.editContact.name;
      this.form.idType = this.editContact.idType;
      this.form.idNumber = this.editContact.idNumber;
      this.form.mobile = this.editContact.mobile;
      this.form.birthDay = this.editContact.birthDay;
    }
    this.requestHistoryContactList();
  },
  methods: {
    // 请求历史联系人
    // @request(true)
    async requestHistoryContactList() {
      const list = await tripService.queryTripHistoryContacts();
      this.oftenResult = [...list];
      this.tableData = this.oftenResult;
    },
    // 请求搜索联系人
    async requestSearchContactList(searchText) {
      const list = await tripService.queryTripSearchContacts({ name: searchText });
      this.searchResult = [...list];
      this.tableData = this.searchResult;
    },
    // 请求新增联系人
    @request(true)
    async requestAddContactList(obj) {
      await tripService.queryTripAddContacts(obj);
      this.requestHistoryContactList();
    },
    // 请求编辑联系人
    @request(true)
    async requestUpdateContactList(obj) {
      await tripService.queryTripUpdateContacts(obj);
      this.changeTabs(this.tabList[0]);
      this.requestHistoryContactList();
    },
    // // 切换页签(常用/新增)
    // changeTabs(res) {
    //   if (res.id === "often") {
    //     this.activeTab = "often";
    //     this.headTitle = "常用人员1";
    //   } else {
    //     this.activeTab = "add";
    //     this.headTitle = "新增人员";
    //     this.form.name = "";
    //     this.form.idType = "";
    //     this.form.idNumber = "";
    //     this.form.mobile = "";
    //     this.form.birthDay = "";
    //   }
    // },
    // // 关闭XX
    // closeClick() {
    //   this.dialogContactVisible = false;
    // },
    // // 行点击监听
    // rowClick(row) {
    //   this.confirmSelectContact(row);
    // },
    // cellMouseEnter(row) {
    //   if (row.flag === "000") {
    //     row.isEdit = true;
    //   }
    // },
    // cellMouseLeave(row) {
    //   row.isEdit = false;
    // },
    // 编辑事件
    editClick(scope) {
      this.activeTab = "add";
      this.form.id = scope.row.id;
      this.form.name = scope.row.name;
      this.form.idType = scope.row.idType;
      this.form.idNumber = scope.row.idNumber;
      this.form.mobile = scope.row.mobile;
      this.form.birthDay = scope.row.birthDay;
    },
    // // 确定选择
    // confirmSelectContact(obj) {
    //   if (this.existList.contain(obj)) {
    //     this.$message("不能重复添加");
    //   } else {
    //     this.$emit("getEnContactConfirm", obj);
    //     this.closeClick();
    //   }
    // },
    // // 新增/编辑 的校验
    // confirmClick() {
    //   this.$refs.form.validate((valid) => {
    //     if (valid) {
    //       if (this.form.id) {
    //         this.requestUpdateContactList(this.form);
    //       } else {
    //         this.requestAddContactList(this.form);
    //       }
    //     } else {
    //       this.$alert("请根据提示完成对应内容的填写或选择");
    //       return false;
    //     }
    //     return false;
    //   });
    // },
    // 脱敏处理
    numDealer(num) {
      // 处理数字,除前三位和后四位其他均隐藏
      const { length } = num;
      const numList = num.split("");
      if (length <= 7) {
        numList.splice(0, length - 4, ...Array(length - 4).fill("*"));
      } else {
        numList.splice(3, length - 7, ...Array(length - 7).fill("*"));
      }
      return numList.join("");
    }
  }
};
